import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/general-care/childrens-itch-front-product.webp";
import backImage from "images/baby/general-care/childrens-itch-back-product.webp";
import frontZoomImage from "images/baby/general-care/childrens-itch-front-product-zoom.webp";
import backZoomImage from "images/baby/general-care/childrens-itch-back-product-zoom.webp";

import img2 from "images/baby/general-care/aqu-itch-relief-ointment-flare-reduction.webp";
import img3 from "images/baby/general-care/euc-aqua-intment-itch-severity.webp";
import img4 from "images/baby/general-care/itch-relief-resource.webp";
import img5 from "images/baby/general-care/advanced-repair-callout-footer.webp";
import img6 from "images/baby/general-care/calming-lotion-callout-footer.webp";

import "./aquaphorchildrensitchreliefointment.scss";

function AquaphorChildrensItchReliefOintmentPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-generalcare page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Children's Itch Relief Ointment"
        pageDescription="Learn about Aquaphor Children's Itch Relief Ointment"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#5daee1"
              categoryName="GENERAL CARE"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Children's Itch Relief Ointment"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Soothes and relieves itch for up to 12 hours<sup>1</sup>
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Children's Itch Relief Ointment
                  </span>{" "}
                  is formulated with 1% hydrocortisone (external analgesic) to
                  provide immediate and long-lasting itch relief from eczema
                  flares and skin irritations.
                </p>
                <ul className="picton-bullets">
                  <li>
                    <span>Maximum strength</span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically proven results
                    </span>
                  </li>
                  <li>
                    <span>
                      Gentle and safe for sensitive skin, including pediatric
                      patients over <nobr>2 years</nobr> of age
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">
                  1% hydrocortisone (external analgesic)
                </p>
                <ul className="picton-bullets">
                  <li>
                    <span> Soothes and relieves itching</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Key ingredients
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Menthoxypropanediol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Soothes skin</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Panthenol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Softens and conditions skin</span>
                      </li>
                      <li>
                        <span>Binds water to support moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Lanolin alcohol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Supports barrier repair</span>
                      </li>
                      <li>
                        <span>Defends against moisture loss</span>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Petrolatum</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Locks in moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Glycerin</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Binds water</span>
                      </li>
                      <li>
                        <span>Replenishes moisture reservoir</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Bisabolol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Soothes dry, irritated skin</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold pictonblue-text sub-title">
                    Formulated for long-lasting itch relief
                  </p>
                  <ul className="checkmarks pictonblue">
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                    <li>
                      <span>PARABEN-FREE</span>
                    </li>
                    <li>
                      <span>HYPOALLERGENIC</span>
                    </li>
                  </ul>
                </div>
                <p className="footnotes">
                  <span className="font-bold">Reference: 1.</span> Data on file.
                  Beiersdorf Inc.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="FLARE REDUCTION"
              productThemeColor="baby-picton-blue"
              id="flarereduction"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Graph with reduced symptoms of eczema flares"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Pediatric
                    subjects were assessed prior to application of Aquaphor Itch
                    Relief Ointment, then applied the test material to the
                    assigned areas twice daily for 7 days, and reassessed at Day
                    7 (n=32).
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant compared to baseline (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    <p className="spacer">
                      53.6% reduction in erythema at <nobr>Day 7</nobr> vs
                      baseline
                      <sup>1**</sup>
                    </p>
                    <p className="spacer">
                      69.5% reduction in pruritus at <nobr>Day 7</nobr> vs
                      baseline
                      <sup>1**</sup>
                    </p>
                    <p className="spacer">
                      57.6% reduction in excoriation at <nobr>Day 7</nobr> vs
                      baseline
                      <sup>1**</sup>
                    </p>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="ITCH REDUCTION"
              productThemeColor="baby-picton-blue"
              id="itchreduction"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Graph with reduced severity of itch"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Seven-day
                    study of adult subjects with dry and itchy skin to determine
                    the tolerability and efficacy of Aquaphor Itch Relief
                    Ointment to alleviate itch due to atopic dermatitis (n=30).
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant compared to Day 1 baseline (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    57.6% reduction <br className="show-desktop" />
                    from Day 1 to <br className="show-desktop" />
                    <nobr>
                      Day 7<sup>1**</sup>
                    </nobr>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource gray">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "pediatric-eczema-relief-regimen",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation with this resource
                  </p>
                  <p className="reg-text">
                    Offer your patients a collection of specialized skin care
                    solutions for babies' eczema-prone skin.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img4}
                  alt="Itch relief resource"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="EUCERIN® ADVANCED REPAIR CREAM"
                  caption="For immediate and <nobr>long-lasting</nobr> <br class='show-desktop' /> moisture"
                  buttonText="EUCERIN<sup>®</sup> ADVANCED REPAIR CREAM"
                  buttonClass="long"
                  buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img6}
                  imgAlt="EUCERIN® ITCH RELIEF"
                  caption="Choose a menthol-enriched <br class='show-desktop' /> formula for a calming effect"
                  buttonText="EUCERIN<sup>®</sup> ITCH RELIEF"
                  buttonUrl="/body/itch/eucerinitchrelieflotion"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AquaphorChildrensItchReliefOintmentPage;
